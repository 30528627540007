/* eslint-disable */
//IMPORTS
import { showAlert } from './alerts.mjs';
import { AJAX } from './helpers.mjs';
import {
  setSuccessFor,
  setErrorFor,
  allSuccess,
  resetInput,
} from './setValidationIcons.mjs';
// GLOBAL VARIABLES
const email = document.getElementById('email');
const password = document.getElementById('password');

// MAIN FUNCTIONS
//-- LISTENERS

function addListeners(loginForm) {
  //-- validate on submit
  loginForm.addEventListener('submit', verifyLogin);

  //-- validate on blur
  loginForm.addEventListener('blur', validateOnBlur, true);

  //-- reset input box to normal  (after 'success' or 'error') when changing value

  loginForm.addEventListener('input', resetInput);
}

//-- verify email
function isEmail(email) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}

//-- check inputs value
function checkEmail() {
  const emailValue = email.value.trim();
  if (!isEmail(emailValue)) {
    setErrorFor(email, 'Email invalido!');
  } else {
    setSuccessFor(email);
  }
}

function checkPassword() {
  const passwordValue = password.value;
  if (passwordValue.includes(' ')) {
    setErrorFor(password, 'No puede contener espacios en blanco!');
  } else if (passwordValue.length < 8) {
    setErrorFor(password, 'No de menos de 8 caracteres!');
  } else {
    setSuccessFor(password);
  }
}

//-- verify login

function verifyLogin(e) {
  e.preventDefault();
  checkEmail();
  checkPassword();
  if (allSuccess()) {
    login();
  }
}

//-- validate on blur

function validateOnBlur(e) {
  const fieldValue = e.target.value;
  if (fieldValue != '') {
    const targetID = e.target.id;
    if (targetID === 'email') {
      checkEmail(e.target);
    }
    if (targetID === 'password') {
      checkPassword(e.target);
    }
  }
}

//-- login

async function login() {
  try {
    const res = await AJAX('/login', {
      body: {
        email: email.value.trim(),
        password: password.value,
      },
    });
    if (res.status === 'success') {
      showAlert('success', 'Conectado: ¡Bienvenido Admin!');
      window.setTimeout(() => {
        location.assign('/');
      }, 2000);
    }
  } catch (err) {
    showAlert('error', err.message);
    setErrorFor(email, 'Invalido: verifiquelo.');
    setErrorFor(password, 'Invalido: verifiquelo.');
  }
}

export default addListeners;
