/* eslint-disable */
//IMPORTS
import { showAlert } from './alerts.mjs';
import { AJAX } from './helpers.mjs';
import {
  setSuccessFor,
  setErrorFor,
  allSuccess,
  resetInput,
} from './setValidationIcons.mjs';

// GLOBAL VARIABLES
const title = document.getElementById('title');
const text = document.getElementById('text');
const summary = document.getElementById('summary');
const tags = document.getElementById('tags');
const tagList = document.getElementById('tag-list');
const publishedAt = document.getElementById('publishedAt');
const modal = document.getElementById('modal');
const editBtn = document.getElementById('edit-btn');
const saveBtn = document.getElementById('save-btn');
const updateBtn = document.getElementById('update-btn');

// MAIN FUNCTIONS
//-- LISTENERS

function addListeners(createForm) {
  //-- validate on submit
  createForm.addEventListener('submit', verifyCreate);

  //-- validate on blur
  //createForm.addEventListener('blur', validateOnBlur, true);

  //check if tags are checked

  if (tagList) {
    tagList.addEventListener('change', addRemoveTag);
  }

  editBtn.addEventListener('click', playAnimationOut);

  if (saveBtn) {
    saveBtn.addEventListener('click', createPost);
  }

  if (updateBtn) {
    updateBtn.addEventListener('click', updatePost);
  }

  //-- reset input box to normal  (after 'success' or 'error') when changing value

  createForm.addEventListener('input', resetInput);
}

//--verifyCreate
//TODO: REMOVE ALL return true/false from check functions

function checkField(field, maxLength) {
  const fieldValue = field.value.trim();
  if (fieldValue.length > maxLength) {
    setErrorFor(
      field,
      `Ha ingresado ${fieldValue.length} caracteres, ${maxLength} es el máximo permitido.`
    );
    return false;
  } else {
    setSuccessFor(field);
  }
  return true;
}

async function titleIsUnique() {
  try {
    const res = await AJAX('/post/validatetitle', {
      body: { title: title.value.trim() },
    });
    if (res.status !== 'success') {
      showAlert('error', 'Título duplicado');
      setErrorFor(
        title,
        'Título duplicado: este título ya existe en la BD, por favor cámbielo'
      );
    }
  } catch (err) {
    showAlert('error', err.message);
    setErrorFor(
      title,
      'Hubo un problema en la verificación. Vuelva a intentarlo'
    );
  }
}

function sanatizeTags() {
  let tagsValue = tags.value.trim().toLowerCase();
  if (!tagsValue) return '';
  tagsValue = tagsValue.endsWith(',') ? tagsValue.slice(0, -1) : tagsValue;
  return tagsValue.split(',').map((tag) => tag.trim());
}

function checkTags() {
  const tagsArray = sanatizeTags();
  if (tagsArray) {
    if (
      !tagsArray.every(
        (tag) => tag !== '' && tag.search(/[^a-zñáéíóúü\s]/g) === -1
      )
    ) {
      setErrorFor(
        tags,
        `Invalido: solo palabras separadas por comas están permitidas.`
      );
      return false;
    }
  }
  setSuccessFor(tags);
  return true;
}

function checkDate() {
  setSuccessFor(publishedAt);
}

function addRemoveTag(e) {
  const tagsValue = tags.value.trim().toLowerCase();
  if (e.target.checked) {
    // checks whether to add a comma or not with the tag
    tags.value = !tagsValue
      ? e.target.value // no tags --> just adds the tag
      : tagsValue.endsWith(',')
      ? `${tagsValue} ${e.target.value}` // there are tags and the string ends with a comma
      : `${tagsValue}, ${e.target.value}`; // no comma
  } else {
    const tagsArray = tagsValue
      .split(',')
      .map((tag) => tag.trim())
      .filter((tag) => tag !== e.target.value);
    tags.value = tagsArray.join(', ');
  }
}

async function verifyCreate(e) {
  e.preventDefault();
  let sameTitle = false;
  if (window.location.pathname.includes('/edit/')) {
    sameTitle = title.value.trim() === title.dataset.value;
  }
  try {
    if (checkField(title, 50) && !sameTitle) await titleIsUnique();
    checkField(text, 15000);
    checkField(summary, 200);
    checkTags();
    if (publishedAt) checkDate();

    if (allSuccess()) {
      injectModalValues();
      openModal();
    }
  } catch (err) {
    console.log(err);
  }
}

function injectModalValues() {
  const timeTag = document.getElementById('time-tag');
  const titleTag = document.getElementById('title-tag');
  const articleTag = document.getElementById('article-tag');
  const listTag = document.getElementById('list-tag');
  const post = consolidateData();
  // TIME TAG
  const dateShort = post.publishedAt.toLocaleString('es-ES', {
    dateStyle: 'short',
  });
  const dateLong = post.publishedAt
    .toLocaleString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' })
    .replace(' de 20', ', 20');
  timeTag.setAttribute('datetime', dateShort);
  timeTag.textContent = dateLong;
  // TITLE TAG
  titleTag.textContent = post.title;
  // ARTICLE TAG - PARAGRAPHS
  const paragraphs = post.text.split('\n').filter((el) => el != '');
  if (articleTag.childElementCount > 0) removeChildren(articleTag);
  for (const para of paragraphs) {
    let paraTag = document.createElement('p');
    paraTag.className = 'paragraph paragraph--post';
    paraTag.innerHTML = para;
    articleTag.appendChild(paraTag);
  }
  // LIST TAG FOR TAGS
  if (listTag.childElementCount > 0) removeChildren(listTag);
  for (const tag of post.tags) {
    const listItem = document.createElement('li');
    listItem.className = 'tag tag--big';
    const linkTag = document.createElement('a');
    linkTag.className = 'tag__label';
    linkTag.setAttribute('href', '#');
    linkTag.textContent = tag;
    listItem.appendChild(linkTag);
    listTag.appendChild(listItem);
  }
  if (updateBtn) {
    updateBtn.disabled = Object.keys(changedFields()).length === 0;
  }
}

function removeChildren(parent) {
  while (parent.firstChild) {
    parent.firstChild.remove();
  }
}

function openModal() {
  document.body.style.overflowY = 'hidden';
  modal.classList.add('modal--open');
  modal.style.animation = 'modalIn .7s forwards';
}

function playAnimationOut() {
  modal.style.animation = 'modalOut .7s forwards';
  modal.addEventListener('animationend', closeModal);
}

function closeModal(e) {
  if (e.animationName == 'modalOut') {
    modal.classList.remove('modal--open');
    document.body.removeAttribute('style');
    modal.removeEventListener('animationend', closeModal);
  }
}

function consolidateData() {
  const date = publishedAt
    ? new Date(publishedAt.value)
    : new Date(document.querySelector('p.forms__label').dataset.date);
  return {
    title: title.value.trim(),
    text: text.value.trim(),
    summary: summary.value.trim(),
    tags: sanatizeTags(),
    publishedAt: date,
  };
}

async function createPost() {
  try {
    const res = await AJAX('/post/create', {
      body: consolidateData(),
    });
    if (res.status === 'success') {
      showAlert('success', 'Post guardado en la BD.');
      window.setTimeout(() => {
        location.reload();
      }, 2000);
    }
  } catch (err) {
    showAlert('error', err.message);
    showAlert(
      'error',
      'Verifique la conexión y/o los datos ingresados y vuelva a intentarlo.'
    );
    playAnimationOut();
  }
}

function changedFields() {
  const formFields = document.querySelectorAll('[data-value]');
  const changedValues = Array.from(formFields)
    .filter((el) => el.dataset.value !== el.value)
    .map((el) => ({ [el.id]: el.value }));
  const changedObject = Object.assign({}, ...changedValues);
  if (changedObject.tags) {
    const strTags = changedObject.tags;
    changedObject.tags = strTags.split(',').map((tag) => tag.trim());
  }
  return changedObject;
}

async function updatePost(e) {
  e.preventDefault();
  try {
    const endpoint = `/post/${window.location.pathname.replace(
      'edit',
      'update'
    )}`;

    const res = await AJAX(endpoint, {
      body: changedFields(),
      method: 'PATCH',
    });
    if (res.status === 'success') {
      showAlert('success', 'El Post ha sido actualizado');
      window.setTimeout(() => {
        location.assign(`/post/${res.data.slug}`);
      }, 2000);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
}

export default addListeners;
