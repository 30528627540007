/* eslint-disable */
import { showAlert } from './alerts.mjs';
import { AJAX } from './helpers.mjs';

const confirmPopup = document.getElementById('confirm');
const cancelBtn = document.getElementById('cancel-btn');
const confirmBtn = document.getElementById('confirm-btn');

function addListeners(deleteLink) {
  deleteLink.addEventListener('click', openConfirmPopup);

  cancelBtn.addEventListener('click', playAnimationOut);

  confirmBtn.addEventListener('click', deletePost);
}

function openConfirmPopup() {
  document.body.style.overflowY = 'hidden';
  confirmPopup.classList.add('confirm--open');
  confirmPopup.style.animation = 'modalIn .7s forwards';
}

function playAnimationOut() {
  confirmPopup.style.animation = 'modalOut .7s forwards';
  confirmPopup.addEventListener('animationend', closeConfirmPopup);
}

function closeConfirmPopup(e) {
  if (e.animationName == 'modalOut') {
    confirmPopup.classList.remove('confirm--open');
    document.body.removeAttribute('style');
    confirmPopup.removeEventListener('animationend', closeConfirmPopup);
  }
}

async function deletePost(e) {
  let slug;
  if (window.location.pathname.includes('/post/')) {
    slug = { slug: window.location.pathname.replace('/post/', '') };
  } else if (window.location.pathname.includes('/edit/')) {
    slug = { slug: window.location.pathname.replace('/edit/', '') };
  }
  e.preventDefault();
  try {
    const res = await AJAX('/post/delete', { body: slug, method: 'DELETE' });
    if (res.status === 204) {
      showAlert('success', 'Post eliminado de la BD.');
      window.setTimeout(() => {
        location.assign('/');
      }, 2000);
    }
  } catch (err) {
    showAlert('error', err.message);
    showAlert(
      'error',
      'Verifique la conexión y/o los datos ingresados y vuelva a intentarlo.'
    );
    playAnimationOut();
  }
}

export default addListeners;
