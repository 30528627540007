/* eslint-disable */
//IMPORTS
import showPostsListener from './modules/showPosts.mjs';
import loginListener from './modules/login.mjs';
import logoutListener from './modules/logout.mjs';
import createListener from './modules/create.mjs';
import allPostsFilterListener from './modules/allPosts.mjs';
import deleteListener from './modules/deletePost.mjs';

const APP = (function () {
  document.addEventListener('DOMContentLoaded', init);
  // MAIN FUNCTIONS
  function init() {
    // DOM ELEMENTS
    const btnMostrar = document.getElementById('btnMostrar');
    const loginForm = document.getElementById('login-form');
    const logoutLink = document.getElementById('logout');
    const createForm = document.getElementById('create-form');
    const filterButton = document.getElementById('filter-btn')
    const filterForm = document.getElementById('filter-form');
    const deleteLink = document.getElementById('delete-link');

    
    if (btnMostrar) {
      showPostsListener();
    }

    if (loginForm) {
      loginListener(loginForm);
    }

    if (logoutLink) {
      logoutListener(logoutLink);
    }

    if (createForm) {
      createListener(createForm);
    }

    if (filterButton) {
      filterButton.addEventListener('click', () => {
        filterForm.classList.toggle('filter__form--visible');
        filterButton.classList.toggle('btn__filter--active')
      })
    }

    if (filterForm) {
      allPostsFilterListener(filterForm);
    }

    if (deleteLink) {
      deleteListener(deleteLink);
    }
  }
})();
