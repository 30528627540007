/* eslint-disable */
import { showAlert } from './alerts.mjs';
import { AJAX } from './helpers.mjs';

function addListeners(logoutLink) {
  logoutLink.addEventListener('click', logout);
}

async function logout() {
  try {
    await AJAX('/logout');
    showAlert('success', 'Logged out: ¡Hasta pronto, Admin!');
    window.setTimeout(() => {
      location.assign('/');
    }, 2000);
  } catch (err) {
    showAlert('error', 'Error en el Logout. Por favor vuelva a intentarlo.');
  }
}

export default addListeners;
