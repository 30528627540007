/* eslint-disable */
//IMPORTS

// GLOBAL VARIABLES

// MAIN FUNCTIONS
//-- LISTENERS

function addListeners(filterForm) {
  //-- validate on submit
  filterForm.addEventListener('change', changeFilter);
}

function changeFilter(e) {
  e.target.form.submit();
}

export default addListeners;
