/* eslint-disable */
//IMPORTS

// GLOBAL VARIABLES
const postsSecondPage = document.querySelectorAll('.card--invisible');

// MAIN FUNCTIONS
//-- LISTENERS

function addListeners() {
  btnMostrar.addEventListener('click', showPosts);
  btnTodos.addEventListener('click', showAll);
}

//-- SHOW POSTS

function showPosts() {
  postsSecondPage.forEach((post) => post.classList.toggle('card--invisible'));
  if (btnMostrar.textContent.includes('menos')) {
    btnMostrar.textContent = 'Ver más';
    window.scrollBy({
      top: -230,
      behavior: 'smooth',
    });
  } else {
    btnMostrar.textContent = 'Ver menos';
  }
  btnMostrar.blur();
}

function showAll() {
  location.assign('/posts/1');
}

export default addListeners;
