/* eslint-disable */
import { TIMEOUT_SECONDS } from './config.mjs';

function timeout(s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(
        new Error(
          `La conexión tomó mucho tiempo... Vuelva a intentarlo más tarde.`
        )
      );
    }, s * 1000);
  });
}

export async function AJAX(endpoint, { body, ...customOptions } = {}) {
  try {
    const headers = { 'content-type': 'application/json' };
    const options = {
      method: body ? 'POST' : 'GET',
      headers,
      ...customOptions,
    };
    if (body) {
      options.body = JSON.stringify(body);
    }
    const res = await Promise.race([
      fetch(endpoint, options),
      timeout(TIMEOUT_SECONDS),
    ]);

    if (options.method === 'DELETE') {
      // eslint 7.5 supports optional chaining
      if (!res.ok) throw new Error(`Error al borrar: (${res.status})`);
      return res;
    }
    const data = await res.json();
    if (!res.ok) throw new Error(`${data.message} (${res.status})`);
    return data;
  } catch (err) {
    throw err;
  }
}
