/* eslint-disable */
//-- set error icon + error message

export const setErrorFor = (input, error) => {
  const label = input.parentElement;
  if (label.classList.contains('success')) {
    label.classList.replace('success', 'error');
  } else {
    label.classList.add('error');
  }
  label.querySelector('.forms__error').textContent = error;
};

//-- set success icon

export const setSuccessFor = (input) => {
  const label = input.parentElement;
  if (label.classList.contains('error')) {
    label.classList.replace('error', 'success');
  } else {
    label.classList.add('success');
  }
};

export const allSuccess = () => {
  const labels = document.querySelectorAll('label.forms__label');
  for (let label of labels) {
    if (label.classList.contains('success')) {
      continue;
    } else {
      return false;
    }
  }
  return true;
};

//-- reset input on value change

export const resetInput = (e) => {
  const label = e.target.parentElement;
  label.classList.remove('error', 'success');
};
